<template>
  <div :class="['lz-button', type, {'disabled': disabled}]">
    <span>{{title}}</span>
  </div>
</template>

<script>
// Note: 引用该组件时，需要在父组件样式表中设置其 width 和 height
export default {
  data () {
    return {
    }
  },
  props: {
    // 按钮名称
    title: {
      type: String,
      default: '取消'
    },
    // 按钮类型：default/primary
    type: {
      type: String,
      default: 'default'
    },
    // 按钮是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .lz-button {
    user-select: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 24px;
    border:1px solid #E6E9ED;
    &.default {
      color: #333;
      background: #FFF;
      &.disabled {
        background: #EEE;
        color: #CCC;
      }
      &:active {
        background: #EEE;
      }
    }
    &.primary {
      color: #fff;
      background: #FFE013;
      border: none;
      &.disabled {
        background: #E5E5E5;
      }
      &:active {
        background: #dbc011;
      }
    }
    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
</style>
